export const genderTypes = [
  { id: 1, value: 1, name: 'Male' },
  { id: 2, value: 2, name: 'Female' },
  { id: 3, value: 3, name: 'Do not wish to specify' },
];
export const employmentStatusTypes = [
  { id: 1, value: 1, name: 'Student' },
  { id: 2, value: 2, name: 'Full Time' },
  { id: 3, value: 3, name: 'Part Time' },
  { id: 4, value: 4, name: 'Self Employed' },
  { id: 5, value: 5, name: 'Freelance' },
  { id: 6, value: 6, name: 'Internship' },
  { id: 7, value: 7, name: 'Trainee' },
];

export const skillLevels = [
  { id: 1, value: 1, name: 'Beginner' },
  { id: 2, value: 2, name: 'Intermediate' },
  { id: 3, value: 3, name: 'Expert' },
];
export const skillType = [
  { id: 1, value: 1, name: 'Primary' },
  { id: 2, value: 2, name: 'Secondary' },
];

export const employmentStatusEnum = {
  0: '',
  1: 'Student',
  2: 'Full Time',
  3: 'Part Time',
  4: 'Self Employed',
  5: 'Freelance',
  6: 'Internship',
  7: 'Trainee',
};

// export const responseTypes = [
//   { id: 1, value: 1, name: 'Yet to respond' },
//   { id: 2, value: 2, name: 'Accepted' },
//   { id: 3, value: 3, name: 'Rejected' },
//   { id: 4, value: 4, name: 'Cancelled' },
//   { id: 4, value: 4, name: 'Cancelled' },
// ];
export const responseTypes = [
  { id: 8, value: 'All', name: 'All' },
  { id: 1, value: 1, name: 'Yet To Respond' },
  { id: 2, value: 2, name: 'Accepted' },
  { id: 3, value: 3, name: 'Rejected' },
  { id: 4, value: 4, name: 'Cancelled' },
  { id: 5, value: 5, name: 'Deactivated' },
  { id: 6, value: 6, name: 'Completed' },
  { id: 7, value: 7, name: 'Failed' },
];

export const modeTypes = [
  { id: 1, value: 'Offline', name: 'Face to Face' },
  { id: 2, value: 'Online', name: 'Online' },
];

export const AssessmentTypes = [
  { id: 1, value: 1, name: 'Self Assessment' },
  { id: 2, value: 2, name: 'Jobs Assessment' },
];

export const AcceptedStatusTypes = [
  { id: 0, label: 'All' },
  { id: 1, label: 'Open' },
  { id: 2, label: 'Accepted' },
  { id: 3, label: 'Rejected' },
  { id: 4, label: 'In Progress' },
  { id: 5, label: 'Closed' },
  { id: 6, label: 'Deactivate' },
  { id: 7, label: 'Hold' },
  // { id: 7, label: 'Unhold' },
];

export const StepTypes = {
  CREATE_PROFILE: 'create_profile',
  VALIDATE_PROFILE: 'validate_profile',
  SELF_ASSESSMENT: 'self_assessment',
  ENDORSEMENT: 'endorsement',
  SCENARIO_QUESTIONS: 'scenario_questions',
};

export const StepState = {
  IN_PROGRESS: 1,
  COMPLETED_STEP: 2,
  NOT_STARTED: 3,
};

export const RoleTypeEnum = {
  SuperAdmin: 1,
  Client: 2,
};

export const RoleEnumValidation = {
  SuperAdmin: 'SuperAdmin',
  Client: 'Client',
  Candidate: 'Candidate',
};

export const JobProfileTypeEnum = {
  1: 'FullTime',
  2: 'Gig',
  3: 'Internship',
};

export const AssociatedWithEnum = {
  0: 'Company',
  1: 'Education',
  2: 'Other',
};
