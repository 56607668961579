import { RoleEnumValidation } from '@/src/data/Enum';
import { localStorageUtil } from '@/utils/CommonFunctions/localStorageUtil';
import { CircularProgress, Container } from '@mui/material';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';

const candidate_UnAuthorized_Pages = ['/login', '/signup'];
const client_UnAuthorized_Pages = ['/client/login'];

const roleType = '/client/login/choose_role_type';
const candidate_Authorized_Page = '/profile';
const candidate_UnAuthorized_Page = '/login';
const client_Authorized_Page = '/client/dashboard';
const client_UnAuthorized_Page = '/client/login';
const super_admin_Authorized_Page = '/admin/assessment/assessmentlist/';

const withAuth = (WrappedComponent, allowedRoles) => {
  const AuthWrapper = props => {
    const router = useRouter();

    const shouldLog = useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const currentUserDetails = localStorageUtil.getItem('currentUserDetails');
    const isLoggedIn = Boolean(currentUserDetails);
    // this is which type of login
    const isCandidateLogin =
      currentUserDetails?.type === RoleEnumValidation.Candidate;
    const isClientLogin =
      currentUserDetails?.type === RoleEnumValidation.Client;
    const isSuperAdminClientLogin =
      currentUserDetails?.type === RoleEnumValidation.SuperAdmin &&
      currentUserDetails?.contact?.clientId;
    const isSuperAdminLogin =
      currentUserDetails?.type === RoleEnumValidation.SuperAdmin &&
      !currentUserDetails?.contact?.clientId;

    // which types of role allowed
    const candidateRole = allowedRoles === 'user';
    const clientRole = allowedRoles === 'client';
    const superAdminRole = allowedRoles === 'super_admin';

    const navigateToPath = useCallback(
      path => {
        setIsLoading(true);
        router
          .push(path, undefined, { shallow: true })
          .then(() => setIsLoading(false));
      },
      [router]
    );
    const navigateToPathCommonPath = useCallback(() => {
      if (isSuperAdminLogin) {
        navigateToPath(super_admin_Authorized_Page);
      } else if (isClientLogin || isSuperAdminClientLogin) {
        navigateToPath(client_Authorized_Page);
      } else if (isCandidateLogin) {
        navigateToPath(candidate_Authorized_Page);
      }
    }, [
      isSuperAdminLogin,
      isClientLogin,
      isSuperAdminClientLogin,
      isCandidateLogin,
      navigateToPath,
    ]);

    const checkAuth = useCallback(async () => {
      if (!isLoggedIn) {
        if (candidateRole) {
          if (candidate_UnAuthorized_Pages.includes(router.pathname)) {
            setIsLoading(false);
          } else {
            localStorageUtil.setItem('preAuthPath', router.asPath);
            setIsLoading(false);
            navigateToPath(candidate_UnAuthorized_Page);
          }
        } else if (superAdminRole) {
          if (client_UnAuthorized_Pages.includes(router.pathname)) {
            setIsLoading(false);
          } else {
            navigateToPath(client_UnAuthorized_Page);
          }
        } else if (clientRole) {
          if (client_UnAuthorized_Pages.includes(router.pathname)) {
            setIsLoading(false);
          } else {
            navigateToPath(client_UnAuthorized_Page);
          }
        } else {
          setIsLoading(false);
        }
      } else {
        if (candidateRole) {
          if (isCandidateLogin) {
            if (candidate_UnAuthorized_Pages.includes(router.pathname)) {
              console.log(router.pathname);
              navigateToPath(candidate_Authorized_Page);
            } else {
              setIsLoading(false);
            }
          } else {
            navigateToPathCommonPath();
          }
        } else if (superAdminRole) {
          if (isSuperAdminLogin) {
            if (client_UnAuthorized_Pages.includes(router.pathname)) {
              if (router.pathname !== '/client/login/choose_role_type')
                navigateToPath(super_admin_Authorized_Page);
              else {
                setIsLoading(false);
              }
            } else {
              setIsLoading(false);
            }
          } else {
            navigateToPathCommonPath();
          }
        } else if (clientRole) {
          if (isClientLogin || isSuperAdminClientLogin || isSuperAdminLogin) {
            if (router.pathname === roleType) {
              setIsLoading(false);
            } else if (client_UnAuthorized_Pages.includes(router.pathname)) {
              navigateToPath(client_Authorized_Page);
            } else if (isClientLogin || isSuperAdminClientLogin) {
              setIsLoading(false);
            } else {
              navigateToPathCommonPath();
            }
          } else {
            navigateToPathCommonPath();
          }
        } else {
          setIsLoading(false);
        }
      }
    }, [
      isLoggedIn,
      candidateRole,
      superAdminRole,
      clientRole,
      isCandidateLogin,
      isClientLogin,
      isSuperAdminClientLogin,
      isSuperAdminLogin,
      navigateToPath,
      router,
      navigateToPathCommonPath,
    ]);

    useEffect(() => {
      if (shouldLog.current) {
        checkAuth();
      }
    }, [checkAuth]);

    return (
      <LogoLoader isLoading={isLoading} checkAuth={checkAuth}>
        <WrappedComponent {...props} />
      </LogoLoader>
    );
  };

  return AuthWrapper;
};

export default withAuth;

const LogoLoader = ({ isLoading, checkAuth, children }) => {
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (isLoading) {
    return (
      <Container
        maxWidth={false}
        sx={{
          height: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return children;
};
